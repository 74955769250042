@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100..1000&display=swap");

/* TODO focus on the node

.react-flow__node:focus {
  outline-style: auto !important;
  border-radius: 1rem !important;
} */

.react-flow__node-group {
  padding: 0 !important;
  outline: none !important;
  border-width: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.react-flow__node-group.selected {
  padding: 0 !important;
  outline: none !important;
  border-width: 0 !important;
  box-shadow: none !important;
}

path.react-flow__edge-path {
  stroke-width: 2;
  stroke: #060c11 !important;
}

.react-flow__edge:hover .react-flow__edge-path {
  stroke-width: 3;
  stroke: #689dc6 !important;
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke-width: 3;
  stroke: #689dc6 !important;
}

.react-flow__node-baseNode {
  z-index: 1004 !important;
}

.react-flow__edges {
  z-index: 1003 !important;
}

.react-flow__node-group {
  z-index: 1002 !important;
}

.react-flow__handle--text::after {
  /* content: "abc";
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #312453; */
  content: "";
  background-color: #060c11;
  -webkit-mask-image: url("/images/svg/abc.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  -webkit-mask-size: "50%";
  mask-image: url("/images/svg/abc.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: "50%";
  height: 1rem;
  width: 1.25rem;
}

.react-flow__handle--image::after {
  /*content: url(/images/ImageIcon.svg);
   color: #312453;
  height: 0.75rem;
  display: flex; */
  content: "";
  background-color: #060c11;
  -webkit-mask-image: url("/images/svg/ImageIcon.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  -webkit-mask-size: "50%";
  mask-image: url("/images/svg/ImageIcon.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  -webkit-mask-size: contain;
          mask-size: contain;
  height: 0.75rem;
  width: 1rem;
}

.react-flow__handle--audio::after {
  content: "";
  background-color: #060c11;
  -webkit-mask-image: url("/images/svg/AudioWaveIcon.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  -webkit-mask-size: "50%";
  mask-image: url("/images/svg/AudioWaveIcon.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  -webkit-mask-size: fit;
          mask-size: fit;
  height: 1rem;
  width: 1.25rem;
}

.react-flow__handle--file::after {
  content: "";
  background-color: #060c11;
  -webkit-mask-image: url("/images/svg/FileIcon.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  -webkit-mask-size: "50%";
  mask-image: url("/images/svg/FileIcon.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  -webkit-mask-size: 0.625rem;
          mask-size: 0.625rem;
  height: 1rem;
  width: 1.25rem;
}

.react-flow__handle--list::before {
  content: "*";
  width: 6px;
  color: #060c11;
  height: 1rem;
  font-size: 8px;
  line-height: 1.1875rem;
}
.react-flow__handle--connected::before {
  color: white;
}

.react-flow__handle--connected::after {
  background-color: white;
}

.react-flow__attribution {
  visibility: hidden;
}

/* Connection validation classes */

.spotflow .connectingto:not(.valid):not(.connectingfrom):not(.target) {
  border-color: #f04438 !important;
  animation: shakeleft 0.5s;
  animation-iteration-count: 1;
}

.spotflow .connectingto:not(.valid):not(.connectingfrom):not(.source) {
  border-color: #f04438 !important;
  animation: shakeright 0.5s;
  animation-iteration-count: 1;
}

.spotflow .connectingfrom:not(.valid) {
  animation: unset;
}

.spotflow .valid {
  border-color: #060c11 !important;
}

.chakra-collapse {
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
}

@keyframes shakeleft {
  0% {
    transform: translate(10px, -10px) rotate(0deg);
  }

  10% {
    transform: translate(8px, -10px) rotate(-1deg);
  }

  20% {
    transform: translate(12px, -10px) rotate(1deg);
  }

  30% {
    transform: translate(8px, -10px) rotate(0deg);
  }

  40% {
    transform: translate(12px, -10px) rotate(1deg);
  }

  50% {
    transform: translate(8px, -10px) rotate(-1deg);
  }

  60% {
    transform: translate(12px, -10px) rotate(1deg);
  }

  70% {
    transform: translate(8px, -10px) rotate(0deg);
  }

  80% {
    transform: translate(12px, -10px) rotate(-1deg);
  }

  90% {
    transform: translate(8px, -10px) rotate(0deg);
  }

  100% {
    transform: translate(10px, -10px) rotate(0deg);
  }
}

@keyframes shakeright {
  0% {
    transform: translate(-10px, -10px) rotate(0deg);
  }

  10% {
    transform: translate(-8px, -10px) rotate(-1deg);
  }

  20% {
    transform: translate(-12px, -10px) rotate(1deg);
  }

  30% {
    transform: translate(-8px, -10px) rotate(0deg);
  }

  40% {
    transform: translate(-12px, -10px) rotate(1deg);
  }

  50% {
    transform: translate(-8px, -10px) rotate(-1deg);
  }

  60% {
    transform: translate(-12px, -10px) rotate(1deg);
  }

  70% {
    transform: translate(-8px, -10px) rotate(0deg);
  }

  80% {
    transform: translate(-12px, -10px) rotate(-1deg);
  }

  90% {
    transform: translate(-8px, -10px) rotate(0deg);
  }

  100% {
    transform: translate(-10px, -10px) rotate(0deg);
  }
}

/* Code and tiptap editor */
.tiptap-editor-custom {
  padding: 0 !important;
  min-height: 6rem !important;
  height: 100% !important;
  flex: 1 1 !important;
  font-size: 0.8125rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
  color: #060c11 !important;
}

.tiptap p.is-editor-empty::before {
  font-size: 0.8125rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
  color: #a9acb3 !important;
}
/* Code and tiptap editor */
.tiptap-editor-custom.ghost-input {
  padding: 0 !important;
  min-height: 0 !important;
  height: 100% !important;
  flex: 1 1 !important;
  font-size: 0.875rem !important;
}

.code-editor {
  outline: none;
}

/* This is Google Drive picker, we must force its zindex so its above the drawer */
.picker-dialog {
  z-index: 100000 !important;
}

.custom-toast {
  padding: 0 !important;
}

/* Custom styles */
.focused-button {
  background-color: #f0f5fa;
  outline: 2px solid #689dc6;
  outline-offset: 1px;
}

html {
  font-size: 14px;
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: 16px;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 1.25rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1.25rem;
  border: 0.375rem solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

